// 初始化树形赋值, 存储数据键值
export function setOriginTreeListStatus (origin, treeNode, treeList, alreadySelectedData, selectTreeNodeData, vm) {
    treeList.map((item) => {
        let bool = alreadySelectedData.findIndex((key) => {
            return key == item.id
        })
        if (bool !== -1) {
            vm.$set(item, 'checked', true)
            selectTreeNodeData.push(item);
        } else {
            vm.$set(item, 'checked', false)
        }
    });

    vm.$set(origin, treeNode, treeList);

    return origin;
}

// 存储所有的树形对象
export function splicingTreeShape (origin, treeNode, treeList) {
    origin[treeNode] = treeList;
    return origin;
}

export function allCheckedFalse (origin, vm) {
    Object.keys(origin).forEach((item) => {
        origin[item].forEach((subItem) => {
            vm.$set(subItem, 'checked', false)
        })
    })
}


// 改变树形选中/不选中
export function changeCheckedStatus (status, treeListId, treeList, vm) {
    treeList.forEach((item) => {
        treeListId.forEach((subItem) => {
            if (subItem.id == item.id) {
                vm.$set(item, 'checked', status)
            }
        })
    });
    return treeList;
}

// 右侧树形操作左侧列表
export function useTreeListData (initData) {

    function addTreeListData (newData) {
        initData = [
            ...initData,
            ...newData
        ];

        return initData;
    };

    function removeTreeListData (newData) {
        newData.forEach((item) => {
            initData = initData.filter(subItem => subItem.id !== item.id)
        });

        return initData;
    }

    return [
        addTreeListData,
        removeTreeListData
    ]
}

// 去重
export function selectCheckedDeDuplication (list) {
    let map = new Map();
    for (let item of list) {
        if (!map.has(item.id)) {
            map.set(item.id, item);
        };
    };
    return [...map.values()];
}


export function debounce (fn, delay,triggleNow) {
    var t = null;

    var debounced = function () {
        var _self = this,
            args = arguments;

        if(t) {
            clearTimeout(t);
        }

        if(triggleNow) {
            var exec = !t;

            t= setTimeout(function(){
               t = null;
            },delay)

            if(exec){
                fn.apply(_self, args)
            }
        } else {
            t= setTimeout(function(){
                fn.apply(_self, args)
             },delay)
        }

    }

    debounced.remove = function() {
        clearTimeout(t);
        t = null;
    }

    return debounced
}
