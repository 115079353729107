<template>
    <div class="content-main">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <div class="filter-wrap">
                    <expand-filter
                        :formData="formData"
                        @clickBtn="clickBtn"
                        @changeBtnFormType="changeBtnFormType"
                        margin-bottom="0"
                    >
                    </expand-filter>
                    <div class="button-line"></div>
                    <el-button type="enquiry" @click="exportAll">
                        导出
                    </el-button>
                </div>

                <table-data v-loading="tableLoading" ref="table" :config="table_config" :tableData="table_data" @handleSelectionChange="handleSelectionChange">
                    <template #repairCode="{data}">
                        <div
                            class="ellipsis"
                            style="color: #3c7fff; cursor: pointer"
                            @click="tableDetails(data)"
                        >
                            <span v-if="data.approveStats == 1 && data.isRevoke == 1">【撤销】</span>
                            {{ data.dataTitle }}
                        </div>
                    </template>
                </table-data>
                <Pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                />
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    Pagination,
    // 功能组件
    Loading,
    Error
} from 'common-local';
// 逻辑运算
import {
    mapMutations,
    mapState
} from 'vuex';
import TableData from "./Sub/TableData";
import {downloadFile} from "@/libs/utils";
import onResize from "@/mixins/onResize";
import ExpandFilter from "./Sub/ExpandFilter";

export default {
    name: "CoworkingCampusOAOAApprovalRecord",
    mixins: [onResize],
    components: {
        ExpandFilter,
        TableData,
        Pagination,
        Loading,
        Error,
    },
    data() {
        return {
            // 功能组件
            errorShow: false,
            loadingShow: false,
            tableLoading: true,
            // 头部筛选
            formData: {
                data: [
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '请输入表单标题',
                        key: 'dataTitle'
                    },
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '请选择流程状态',
                        key: 'approveStats',
                        list: [
                            // {label: '全部', value: ''},
                            {label: '审批中', value: '1'},
                            {label: '审批通过', value: '2'},
                            {label: '审批拒绝', value: '3'},
                            {label: '已撤销', value: '4'},
                            {label: '审批通过(撤销拒绝)', value: '5'},
                        ]
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '请输入摘要',
                        key: 'dataAbstract'
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: '查询',
                        fn: 'primary'
                    },
                    // {
                    //     type: "enquiry",
                    //     text: '重置',
                    //     fn: 'reset'
                    // },
                ],
                btnFormType: true
            },

            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "表单标题",
                        // prop: "dataTitle",
                        type: "slot",
                        slotName: "repairCode",
                        fixed: false,
                        labelWidth: "360px",
                        tooltip: true,
                        align: 'center'
                    },
                    {
                        label: "摘要",
                        prop: "dataAbstract",
                        labelWidth: "400",
                        type: "tooltipColumnSpecial",
                        splitSign: "、",
                        signReplace: "；",
                        align: 'center'
                    },
                    {
                        label: "发起人",
                        prop: "applyName",
                        align: 'center',
                    },
                    {
                        label: "发起时间",
                        prop: "createTime",
                        labelWidth:'220px',
                        align: 'center',
                        type: "function",
                        callBack: (row) => {
                            if (row.createTime) {
                                return row.createTime.slice(0, 16)
                            }
                        }
                    },
                    {
                        label: "完成时间",
                        prop: "endTime",
                        labelWidth:'220px',
                        align: 'center',
                        type: "function",
                        callBack: (row) => {
                            if (row.endTime) {
                                return row.endTime.slice(0, 16)
                            }
                        }
                    },
                    {
                        label: "流程状态",
                        prop: "approveStats",
                        labelWidth:'220px',
                        type: 'function',
                        align: 'left',
                        callBack(row) {
                            let name = row.currentPersonName ?? ''
                            if (row.approveStats === '0') {
                                return `<div class="ellipsis flexBox" style="display:flex;align-items:center;">
                                            <div style="width:6px;height:6px;border-radius:50%;background:#3C7FFF;margin-right:5px;"></div>
                                            <div>${name}未开始</div>
                                        </div>`
                            } else if (row.approveStats === '1') {
                                return `<div class="ellipsis" style="display:flex;align-items:center;">
                                            <div style=" width:6px;height:6px;border-radius:50%;background:#3C7FFF;margin-right:5px;"></div>
                                            <div class="ellipsis" style="flex:1;">${name}审批中</div>
                                        </div>`
                            } else if (row.approveStats === '2') {
                                return `<div class="ellipsis" style="display:flex;align-items:center;">
                                            <div style="width:6px;height:6px;border-radius:50%;background:#46cc95;margin-right:5px;"></div>
                                            <div>审批通过</div>
                                        </div>`
                            } else if (row.approveStats === '3') {
                                return `<div class="ellipsis" style="display:flex;align-items:center;">
                                            <div style="width:6px;height:6px;border-radius:50%;background:#f56c6c;margin-right:5px;"></div>
                                            <div>审批拒绝</div>
                                        </div>`
                            } else if (row.approveStats === '4') {
                                return `<div class="ellipsis" style="display:flex;align-items:center;">
                                            <div style="width:6px;height:6px;border-radius:50%;background:#f5b942;margin-right:5px;"></div>
                                            <div>已撤销</div>
                                        </div>`
                            } else if (row.approveStats === '5') {
                                return `<div class="ellipsis" style="display:flex;align-items:center;">
                                            <div style="width:6px;height:6px;border-radius:50%;background:#46cc95;margin-right:5px;"></div>
                                            <div>审批通过(撤销拒绝)</div>
                                        </div>`
                            } else if (row.approveStats === '6') {
                                return `<div class="ellipsis" style="display:flex;align-items:center;">
                                            <div style="width:6px;height:6px;border-radius:50%;background:#f56c6c;margin-right:5px;"></div>
                                            <div>审批拒绝</div>
                                        </div>`
                            }
                        }
                    },
                ],
                check: true,
                height: ''
            },
            // 表格数据
            table_data: [],
            // 分页器
            total: 0,
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                schoolId: '',
                formId: '',
                dataTitle: '',
                approveStats: '',
                dataAbstract: '',
            },
            // 表格选中
            tableListSel: [],
        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId,
            OAQueryData: state => state.OAQueryData
        })
    },
    created() {
        this.listQuery.schoolId = this.schoolId
        this.listQuery.formId = this.OAQueryData.formId
        this.getList();
    },
    methods: {
        ...mapMutations(['setOAQueryData']),
        clickBtn(ev) {
            switch (ev.item.fn) {
                case 'primary':
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    })
                    this.getList(1);
                    break;
                case 'reset':
                    this.formData.data.forEach((item) => {
                        item.value = ''
                        this.listQuery[item.key] = ''
                    })
                    this.getList(1)
                    break;
                default:
                    break;
            }
        },
        tableDetails(data) {
            this.setOAQueryData({
                id: data.id, // 申请id
                dataTitle: data.dataTitle, // 申请title
                formId: data.formId,
                backData: {
                    name: this.$route.name,
                    query: {
                        activeName: '2'
                    }
                },
                backParentData: this.OAQueryData.backData,
            })
            this.$router.push({
                name: 'CoworkingCampusOAOAApprovalApplyDetail',
            })
        },
        getList(t) {
            const _this = this;
            if (t === 1) {
                this.listQuery.pageNum = 1
            }
            this._fet('/oa/schoolProcessApply/historicalDataForPC', this.listQuery).then((res) => {
                this.handleRes(res, () => {
                    this.table_data = res.data.data.list;
                    this.total = res.data.data.totalCount;
                    setTimeout(function() {
                        _this.tableLoading = false;
                    }, 1000)
                    if (this.table_data.length > 0) {
                        this.$emit('setTitle', this.table_data[0].dataTitle)
                    }
                })
            })
        },
        handleSelectionChange(data) {
            this.tableListSel = data;
        },
        exportAll() {
            let obj = {
                schoolId: this.schoolId,
                formId: this.listQuery.formId
            }
            // 表格选中数据
            if (this.tableListSel.length > 0) {
                obj.ids = this.tableListSel.map(i => i.id)
                obj.identification = 2
            } else {
                let filter = this.formData.data.filter(i => i.value !== '')
                // 未选中筛选条件，导出全部
                if (filter.length === 0) {
                    obj.identification = 1
                } else {
                    obj.identification = 1
                    // 选中筛选
                    filter.forEach(item => {
                        obj[item.key] = item.value
                    })
                }
            }
            downloadFile({url: '/oa/schoolProcessApply/historicalExport', form: obj}, () => {
                this.$message.success('导出成功')
            }, () => {

            })
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;
    .expand-filter {
        padding: 0;
    }
}
</style>
